<template>
    <ul>
        <li v-for="(page, index) in pages" :key="'page_' + index" :class="{ 'dropdown -hoverable': hasDropdown(page) }">
            <component v-if="page.to" :is="isRouterLink(page) ? 'router-link' : 'a'"
                :to="isRouterLink(page) ? page.to : undefined" :href="isRouterLink(page) ? undefined : page.to" :class="[
                    'navigation__link',
                    'flex',
                    '-align-center',
                    page.class,
                    {
                        'navigation__icon-right': page.iconPosition === 'right',
                        'dropdown-trigger': hasDropdown(page),
                        'dropdown-item': level > 0 && !isMobile,
                    }
                ]" exact>
                <template v-if="page.icon">
                    <icon v-if="iconProvider === 'mdi'" :class="[iconProvider, iconProvider + '-' + page.icon]" />
                    <InlineSvg v-else-if="iconProvider === 'svg'" :src="page.icon" />
                </template>
                <span>{{ page.name || page[`name_${$i18n.locale}`] || $t(page.translation) }}</span>
            </component>
            <div v-else :class="[
                'navigation__link',
                'flex',
                '-align-center',
                page.class,
                {
                    'navigation__icon-right': page.iconPosition === 'right',
                    'dropdown-trigger': hasDropdown(page),
                    'dropdown-item': level > 0 && !isMobile,
                }
            ]">
                <template v-if="page.icon">
                    <icon v-if="iconProvider === 'mdi'" :class="[iconProvider, iconProvider + '-' + page.icon]" />
                    <InlineSvg v-else-if="iconProvider === 'svg'" :src="page.icon" />
                </template>
                <span>{{ page.name || page[`name_${$i18n.locale}`] || $t(page.translation) }}</span>
            </div>

            <NavigationPages v-if="page.pages && page.pages.length" class="transition"
                :class="{ 'dropdown-menu': hasDropdown(page) }" v-bind="{
                    name: page.name || page.translation || page.icon,
                    pages: page.pages,
                    level: level + 1,
                    isMobile,
                    iconProvider,
                }" />
        </li>
    </ul>
</template>
<script>
export default {
    name: 'NavigationPages',
    components: {
        InlineSvg: () => import('vue-inline-svg'),
        NavigationPages: () => import('./NavigationPages.vue'),
    },
    props: {
        isMobile: Boolean,
        iconProvider: String,
        level: Number,
        pages: Array,
    },
    methods: {
        hasDropdown(page) {
            return !this.isMobile && page.pages && page.pages.length;
        },

        isRouterLink(page = {}) {
            return typeof page.to !== 'string' || !page.to.startsWith('http');
        },
    },
};
</script>
