var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',_vm._l((_vm.pages),function(page,index){return _c('li',{key:'page_' + index,class:{ 'dropdown -hoverable': _vm.hasDropdown(page) }},[(page.to)?_c(_vm.isRouterLink(page) ? 'router-link' : 'a',{tag:"component",class:[
                'navigation__link',
                'flex',
                '-align-center',
                page.class,
                {
                    'navigation__icon-right': page.iconPosition === 'right',
                    'dropdown-trigger': _vm.hasDropdown(page),
                    'dropdown-item': _vm.level > 0 && !_vm.isMobile,
                }
            ],attrs:{"to":_vm.isRouterLink(page) ? page.to : undefined,"href":_vm.isRouterLink(page) ? undefined : page.to,"exact":""}},[(page.icon)?[(_vm.iconProvider === 'mdi')?_c('icon',{class:[_vm.iconProvider, _vm.iconProvider + '-' + page.icon]}):(_vm.iconProvider === 'svg')?_c('InlineSvg',{attrs:{"src":page.icon}}):_vm._e()]:_vm._e(),_c('span',[_vm._v(_vm._s(page.name || page[`name_${_vm.$i18n.locale}`] || _vm.$t(page.translation)))])],2):_c('div',{class:[
            'navigation__link',
            'flex',
            '-align-center',
            page.class,
            {
                'navigation__icon-right': page.iconPosition === 'right',
                'dropdown-trigger': _vm.hasDropdown(page),
                'dropdown-item': _vm.level > 0 && !_vm.isMobile,
            }
        ]},[(page.icon)?[(_vm.iconProvider === 'mdi')?_c('icon',{class:[_vm.iconProvider, _vm.iconProvider + '-' + page.icon]}):(_vm.iconProvider === 'svg')?_c('InlineSvg',{attrs:{"src":page.icon}}):_vm._e()]:_vm._e(),_c('span',[_vm._v(_vm._s(page.name || page[`name_${_vm.$i18n.locale}`] || _vm.$t(page.translation)))])],2),(page.pages && page.pages.length)?_c('NavigationPages',_vm._b({staticClass:"transition",class:{ 'dropdown-menu': _vm.hasDropdown(page) }},'NavigationPages',{
                name: page.name || page.translation || page.icon,
                pages: page.pages,
                level: _vm.level + 1,
                isMobile: _vm.isMobile,
                iconProvider: _vm.iconProvider,
            },false)):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }